<script setup lang="ts">
import SpinnerIcon from '@assets/icons/spinner.svg?component';
import { computed, toRefs, useSlots } from 'vue';

type Props = {
  text?: string
  size?: 'md' | 'sm'
  type?: 'button' | 'submit' | 'reset'
  href?: string
  target?: string
  disabled?: boolean
  loading?: boolean
  animated?: boolean
  truncated?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  size: 'md',
  type: 'button',
  href: '',
  target: '_self',
  disabled: false,
  loading: false,
  animated: false,
  truncated: false,
});

const { loading, disabled } = toRefs(props);

const slots = useSlots();

const buttonClass = computed(() => ({
  'btn--size-md': props.size === 'md',
  'btn--size-sm': props.size === 'sm',
  'btn--animated-right': props.animated && slots['icon-right'],
  'btn--animated-left': props.animated && slots['icon-left'] && !slots['icon-right'],
  '!pr-14': props.size === 'md' && slots['icon-right'],
  '!pl-14': props.size === 'md' && slots['icon-left'],
  '!pr-10': props.size === 'sm' && slots['icon-right'],
  '!pl-10': props.size === 'sm' && slots['icon-left'],
  'pointer-events-none': loading.value,
}));

const spinnerClass = computed(() => ({
  'btn__spinner--size-md': props.size === 'md',
  'btn__spinner--size-sm': props.size === 'sm',
  'opacity-0': !loading.value,
}));
</script>

<template>
  <a
    v-if="!!href"
    class="btn"
    :href="href"
    :class="buttonClass"
    :target="target"
  >
    <span
      v-if="slots['icon-left']"
      class="btn__icon-left"
    >
      <slot name="icon-left" />
    </span>

    <span :class="{ 'truncate': truncated }">{{ text }}</span>

    <span
      v-if="slots['icon-right']"
      class="btn__icon-right"
    >
      <slot name="icon-right" />
    </span>
  </a>

  <button
    v-else
    class="btn"
    :type="type"
    :class="buttonClass"
    :disabled="disabled"
  >
    <span
      v-if="slots['icon-left']"
      class="relative shrink-0"
    >
      <SpinnerIcon
        class="btn__spinner absolute h-full w-full"
        :class="spinnerClass"
      />
      <span
        class="btn__icon-left"
        :class="{ 'opacity-0': loading }"
      >
        <slot name="icon-left" />
      </span>
    </span>

    <slot name="text">
      <span :class="{ 'truncate': truncated }">{{ text }}</span>
    </slot>

    <span
      v-if="slots['icon-right']"
      class="relative shrink-0"
    >
      <SpinnerIcon
        class="btn__spinner absolute h-full w-full"
        :class="spinnerClass"
      />
      <span
        class="btn__icon-right"
        :class="{ 'opacity-0': loading }"
      >
        <slot name="icon-right" />
      </span>
    </span>
  </button>
</template>

<style lang="scss" scoped></style>
