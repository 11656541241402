<script setup lang="ts">
import { CounterNum } from '@components/common';
import { formatUnitAmount } from '@helpers/tools';
import { CartItem } from '@models/cart';
import { useCartStore } from '@stores/cart';
import { computed, toRefs } from 'vue';

type Props = {
  item: CartItem;
  disabled?: boolean;
  counter?: boolean;
};

const props = withDefaults(defineProps<Props>(), { disabled: false, counter: true });
const { item, disabled, counter } = toRefs(props);

const cartStore = useCartStore();

const formattedSubtotal = computed(() => formatUnitAmount(item.value.subtotal / 100));
const formattedDiscountedSubtotal = computed(() => formatUnitAmount((item.value.subtotal - item.value.discount) / 100));

const addLoading = computed(() => cartStore.addItemLoadingMap.get(item.value.variantId || 0) || false);

const onIncrease = (): void => {
  cartStore.updateItem(item.value.productId, item.value.variantId);
};

const onDecrease = (): void => {
  cartStore.updateItem(item.value.productId, item.value.variantId, { increase: false });
};
</script>
<template>
  <div
    :key="item.variantId"
    class="mb-10"
  >
    <div>
      <div class="mb-6 grid grid-cols-2">
        <div
          class="text-20 text-deep-blue lg:text-20"
          data-testpl="pharmacy-cart-item-title"
        >
          {{ item.product?.title }}
        </div>
        <div
          v-if="item.variant"
          class="text-right text-16 text-turquoise lg:text-20"
        >
          <template v-if="item.discount !== 0">
            <div class="amount text-warm-grey line-through">
              {{ formattedSubtotal }}
            </div>
            <div class="amount text-turquoise">
              {{ formattedDiscountedSubtotal }}
            </div>
          </template>
          <template v-else>
            <span
              class="amount text-turquoise"
              data-testpl="pharmacy-cart-item-price"
            >
              {{ formattedSubtotal }}
            </span>
          </template>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-10">
      <div class="col-span-6">
        <div
          class="text-16 font-light text-turquoise"
          data-testpl="pharmacy-cart-item-description"
        >
          {{ item.variant?.variantCustomTitle || item.variant?.title }}
        </div>
        <div
          v-if="item.dosage"
          class="mt-5 text-14 font-light"
        >
          Dosage strength: {{ item.dosage/100 }}{{ item.dosageUnit }}
        </div>
      </div>
      <div
        v-if="counter"
        class="col-span-4 flex items-center justify-end"
      >
        <CounterNum
          :value="item.quantity"
          :disabled="addLoading || disabled"
          @increase="onIncrease"
          @decrease="onDecrease"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.counter-button {
  @apply flex h-26 w-26 items-center justify-center rounded-full bg-warm-grey hover:bg-turquoise;
}
</style>
