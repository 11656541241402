import Box from '@assets/icons/box.svg';
import Flag from '@assets/icons/flag.svg';
import Jar from '@assets/icons/jar.svg';
import Pills from '@assets/icons/pills.svg';
import Pipette from '@assets/icons/pipette.svg';
import Speech from '@assets/icons/speech.svg';
import User from '@assets/icons/user.svg';

export type MenuItem = {
  name: string
  desc?: string
  path?: string
  img?: string
  icon?: any
  hidden?: boolean
}
export enum SITE_HANDLE {
    DEFAULT = 'default',
    HMRPROGRAM = 'hmrprogram',
    JRNYS = 'jrnys',
}

export enum PRIVATE_TYPE {
  PATIENT = 'patient',
  PROVIDER = 'provider'
}

export type PrivatePermissions = string[];

// TODO Remove MENU_ITEMS and USER_MENU_ITEMS after tests and communication with dev team
export const MENU_ITEMS: {[key: string]: MenuItem[]} = {
  [PRIVATE_TYPE.PATIENT]: [
    {
      name: 'Treatment plan',
      desc: 'View your medications and guides for usage',
      img: '/assets/img/menu/treatment-plan.svg',
      path: '/patient/treatment',
      icon: Pills,
    },
    {
      name: 'Messages',
      desc: 'View messages from your provider',
      img: '/assets/img/menu/messages.svg',
      path: '/patient/chat',
      icon: Speech,
    },
    {
      name: 'Shipments',
      desc: 'Manage upcoming orders and review order history',
      img: '/assets/img/menu/orders.svg',
      path: '/patient/shipments',
      icon: Box,
    },
    {
      name: 'Pharmacy',
      desc: 'Explore add-on products to enhance your journey',
      img: '/assets/img/menu/pharmacy.svg',
      path: '/patient/pharmacy',
      icon: Jar,
      hidden: true,
    },
    {
      name: 'Progress',
      desc: 'Track your journey from start to finish',
      img: '/assets/img/menu/your-progress.svg',
      path: '/patient/progress',
      icon: Flag,
    },
    {
      name: 'Uploads',
      desc: 'View results and upload documents for your provider',
      img: '/assets/img/menu/lab-results.svg',
      path: '/patient/uploads',
      icon: Pipette,
    },
  ].filter((item: MenuItem) => !item.hidden),
  [PRIVATE_TYPE.PROVIDER]: [
    {
      name: 'Patients',
      desc: '',
      img: '/assets/img/menu/treatment-plan.svg',
      path: '/provider/patients',
      icon: User,
    },
    {
      name: 'Messages',
      desc: '',
      img: '/assets/img/menu/messages.svg',
      path: '/provider/messages',
      icon: Speech,
    },
    {
      name: 'Shipments',
      desc: '',
      img: '/assets/img/menu/orders.svg',
      path: '/provider/shipments',
      icon: Box,
    },
  ].filter((item: MenuItem) => !item.hidden),
};

export const USER_MENU_ITEMS: {[key: string]: MenuItem[]} = {
  [PRIVATE_TYPE.PATIENT]: [
    {
      name: 'My dashboard',
      desc: 'Get an overview of your treatments.',
      img: '/assets/img/menu/dashboard.svg',
      path: '/patient',
    },
    {
      name: 'My profile',
      desc: 'Manage and update your login and payment details.',
      img: '/assets/img/menu/profile.svg',
      path: '/patient/account/profile',
    },
    {
      name: 'Logout',
      img: '/assets/img/menu/logout.svg',
      path: '/actions/users/logout',
    },
  ].filter((item: MenuItem) => !item.hidden),
  [PRIVATE_TYPE.PROVIDER]: [
    {
      name: 'My dashboard',
      desc: 'Get an overview of your treatments.',
      img: '/assets/img/menu/dashboard.svg',
      path: '/provider',
    },
    {
      name: 'My profile',
      desc: 'Manage and update your login and payment details.',
      img: '/assets/img/menu/profile.svg',
      path: '/provider/profile',
    },
    {
      name: 'Logout',
      img: '/assets/img/menu/logout.svg',
      path: '/actions/users/logout',
    },
  ].filter((item: MenuItem) => !item.hidden),
};
