import { ref } from 'vue';
import { useToast } from 'vue-toast-notification';

interface ResponseWithWarnings {
  warnings?: string[];
}

interface Error {
  message: string;
  status: number;
}

export function useFetch<P, R extends ResponseWithWarnings>(fn: (payload: P) => Promise<R>) {
  const toast = useToast();

  const response = ref<R>();
  const error = ref<Error>();
  const warnings = ref<string[]>([]);
  const loading = ref<boolean>(false);

  const execute = (payload: P): Promise<R|null> => {
    error.value = undefined;
    warnings.value = [];
    loading.value = true;

    return fn(payload).then((res) => {
      response.value = res;
      if (res.warnings) {
        warnings.value = res.warnings;
        res.warnings.forEach((w) => {
          toast.warning(w, { duration: 0 });
        });
      }
      return response.value;
    }).catch((e) => {
      error.value = e;
      response.value = undefined;
      toast.error(e.message, { duration: 7000 });

      return null;
    }).finally(() => {
      loading.value = false;
    });
  };

  const clearResponse = () => {
    response.value = undefined;
  };

  return {
    loading,
    execute,
    clearResponse,
    response,
    error,
  };
}
