/**
 * This hook is needed to avoid triggering
 * the same event multiple times
 *
 * For example, if you want the analytics event to be sent only once,
 * even if the client reloads the browser page
 */
export const useMemorize = (prefix: string) => {
  const check = (key: string|number) => {
    if (null === key) {
      return;
    }

    return !!localStorage.getItem(prefix + key);
  };

  const commit = (key: string|number) => {
    if (null === key) {
      return;
    }

    localStorage.setItem(prefix + key, 'true');
  };

  return {
    check,
    commit,
  };
};
