import { CartItemPayload } from '@models/cart';
import { Product } from '@models/product';
import { AnswerMap, MODIFIER, Step as QuizStep, STEP_ANSWER_CONTENT_TYPE } from '@models/quiz';
import { Variant, VARIANT_PERIOD_UNIT, VARIANT_TYPE } from '@models/variant';

const STEP_PRODUCT_TYPES = [
  STEP_ANSWER_CONTENT_TYPE.COMMERCE_PLAN,
  STEP_ANSWER_CONTENT_TYPE.COMMERCE_PRODUCT,
];

type Step = {
    stepId: number
    answers: Answer[],
}

type Answer = {
    answerId: number
    product: Product
    variants: Variant[]
}

type AnswerModifier = {
    answerId: number
    modifier: MODIFIER,
    modifierValue: string
    modifierApplyTo: number[]
}

type StepAnswers = {
    stepId: number
    answerIds: number[]
}

/**
 * Extracts cart items from quiz answers
 *
 * How modifiers work?
 * In the quiz, the quantity of the purchased product can be set after selecting the product.
 * Therefore, there are modifiers that affect the properties of the items of the cart before filling.
 * For example, it can be a quantity, a period, or a dosage.
 *
 * The modifier should be set to such questions as - how many pills do you need, how often do you want shipments
 *
 * If the user selects a response with a modifier and a response with a product,
 * the modifier will be applied to this product, provided that this is possible
 */
export const getCartItemsFromAnswers = (sourceSteps: QuizStep[], sourceAnswers: AnswerMap): CartItemPayload[] => {
  const steps = getSteps(sourceSteps);
  const stepsAnswers = getAnswers(sourceAnswers);
  const modifiers = getModifiers(sourceAnswers);

  const selectedAnswers = filterSubscriptions(getSelectedAnswers(steps, stepsAnswers));

  return selectedAnswers
    .map((selectedAnswer) => {
      const variant = applyPeriodMonth(selectedAnswer, modifiers);
      const quantity = applyQuantityPerMonth(selectedAnswer, modifiers);
      const dosage = applyDosage(selectedAnswer, modifiers);

      return {
        productId: selectedAnswer.product.id,
        variantId: variant?.id ?? null,
        quantity,
        dosage,
        dosageUnit: 'mg',
        variant,
      };
    });
};

const filterSubscriptions = (answers: Answer[]):  Answer[] => {
  const subscriptionAnswer = answers.find((answer) => answer.variants.find((variant) => variant.variantType === VARIANT_TYPE.SUBSCRIPTION));

  if (!subscriptionAnswer) {
    return answers;
  }

  return answers.map((answer) => {
    if (answer.answerId === subscriptionAnswer?.answerId) {
      return {
        ...answer,
        variants: answer.variants.filter(((variant) => variant.variantType === VARIANT_TYPE.SUBSCRIPTION)),
      };
    }

    return {
      ...answer,
      variants: answer.variants.filter(((variant) => variant.variantType !== VARIANT_TYPE.SUBSCRIPTION)),
    };
  });
};

const applyPeriodMonth = (answer: Answer, modifiers: AnswerModifier[]): Variant|null => {
  const modifier = modifiers.find((modifier) => {
    const isPeriodMonth = modifier.modifier === MODIFIER.PERIOD_MONTH;
    const canApplied = modifier.modifierApplyTo.includes(answer.answerId);

    return isPeriodMonth && canApplied;
  }) ?? null;

  if (!modifier) {
    return answer.variants.length ? answer.variants[0] : null;
  }

  const period = Number(modifier.modifierValue);

  return answer.variants.find((variant) => {
    const isPeriodUnitMonth = variant.variantPeriodUnit === VARIANT_PERIOD_UNIT.MONTH;
    const isPeriodEqual = variant.variantPeriod === period;

    return isPeriodUnitMonth && isPeriodEqual;
  }) ?? null;
};

const applyDosage = (answer: Answer, modifiers: AnswerModifier[]): number|null => {
  const modifier = modifiers.find((modifier) => {
    const isDosage = modifier.modifier === MODIFIER.DOSAGE;
    const canApplied = modifier.modifierApplyTo.includes(answer.answerId);

    return isDosage && canApplied;
  }) ?? null;

  if (!modifier) {
    return null;
  }

  return Number(modifier.modifierValue) * 100;
};

const applyQuantityPerMonth = (answer: Answer, modifiers: AnswerModifier[]): number => {
  const modifier = modifiers.find((modifier) => {
    const isQuantityPerMonth = modifier.modifier === MODIFIER.QUANTITY_PER_MONTH;
    const canApplied = modifier.modifierApplyTo.includes(answer.answerId);

    return isQuantityPerMonth && canApplied;
  }) ?? null;

  if (!modifier) {
    return 1;
  }

  return Number(modifier.modifierValue);
};

const getSelectedAnswers = (steps: Step[], stepAnswers: StepAnswers[]) => {
  let selectedAnswers: Answer[] = [];

  stepAnswers.forEach((stepAnswer) => {
    const step = steps.find((step) => step.stepId === stepAnswer.stepId);

    selectedAnswers = [
      ...selectedAnswers,
      ...step?.answers.filter((answer) => stepAnswer.answerIds.includes(answer.answerId)) ?? [],
    ];
  });

  return selectedAnswers;
};

// All the answers that the user has selected
const getAnswers = (sourceAnswers: AnswerMap): StepAnswers[] => {
  return Object.keys(sourceAnswers)
    .map((stepId) => ({
      stepId: Number(stepId),
      answerIds: sourceAnswers[stepId]
        .filter(((answer) => STEP_PRODUCT_TYPES.includes(answer.content[0].type ?? null)))
        .map((answer) => answer.id),
    }))
    .filter((step) => step.answerIds.length);
};

// All steps with answers
const getSteps = (steps: QuizStep[]): Step[] => {
  return steps
    .filter((step) => STEP_PRODUCT_TYPES.includes(step?.answers[0]?.content[0]?.type ?? null))
    .map((step) => ({
      stepId: step.id,
      answers: step?.answers
        .map((answer) => ({
          answerId: answer.id,
          product: {
            ...answer?.content[0].content.product,
            variants: [],
          },
          variants: answer?.content[0].content.variants,
        }))
        .filter((answer) => answer.product?.id),
    }))
    .filter((step) => step.answers?.length);
};

// Modifiers are answers that affect the items in the cart
const getModifiers = (sourceAnswers: AnswerMap): AnswerModifier[] => {
  return Object.keys(sourceAnswers)
    .map((stepId) => ({
      stepId: Number(stepId),
      answers: sourceAnswers[stepId]
        .filter(((answer) => answer.modifier && answer.modifier !== MODIFIER.NONE))
        .map((answer) => ({
          answerId: answer.id,
          modifier: answer.modifier,
          modifierValue: answer.modifierValue,
          modifierApplyTo: answer.modifierApplyTo,
        })),
    }))
    .filter((step) => step.answers.length)
    .reduce((prev: AnswerModifier[], item) => [...prev, ...item.answers], []);
};
