import { request, QueryParams } from '@helpers/fetch';
import {
  QUIZ_TYPE,
  AnswerPayload,
  GetQuizResponse,
  SetAnswersResponse,
  GetAllergiesResponse,
  GetPharmaciesResponse,
  AbortQuizResponse,
  TrackViewQuizStepPayload, TrackSelectQuizAnswerPayload,
} from '@models/quiz';
import { Response } from '@models/response';

export const apiQuiz = {
  getQuiz(quizType: QUIZ_TYPE, query?: QueryParams): Promise<Response<GetQuizResponse>> {
    return request.get(`/api/v1/quizzes/${quizType}`, query);
  },

  abortQuiz(quizType: QUIZ_TYPE): Promise<Response<AbortQuizResponse>> {
    return request.post(`/api/v1/quizzes/${quizType}/abort`);
  },

  setAnswers(payload: AnswerPayload): Promise<Response<SetAnswersResponse>> {
    return request.post(`/api/v1/quizzes/${payload.quizType}/submission`, payload);
  },

  getAllergies(query?: QueryParams): Promise<Response<GetAllergiesResponse>> {
    return request.get('/api/v1/allergies', query);
  },

  getPharmacies(query?: QueryParams): Promise<Response<GetPharmaciesResponse>> {
    return request.get('/api/v1/pharmacies', query);
  },

  trackViewQuizStep(payload: TrackViewQuizStepPayload): Promise<Response<any>> {
    return request.post('/api/v1/track', {
      event: 'ViewQuizStep',
      ...payload,
    });
  },

  trackSelectQuizAnswer(payload: TrackSelectQuizAnswerPayload): Promise<Response<any>> {
    return request.post('/api/v1/track', {
      event: 'SelectQuizAnswer',
      ...payload,
    });
  },
};
