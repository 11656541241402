class ErrorResponse extends Error {
  private status: number | null = null;

  setStatus(status: number) {
    this.status = status;
  }

  getStatus(): number | null {
    return this.status;
  }
}

export type QueryParams = { [key: string]: any }

const querySerialize = (params: QueryParams): string => {
  const paramsArray = [];

  for (const param in params) {
    const encodedKey = encodeURIComponent(param);
    const encodedValue = encodeURIComponent(params[param]);
    paramsArray.push(`${encodedKey}=${encodedValue}`);
  }

  return paramsArray.join('&');
};

const parseResponse = async (data: Response) => {
  if (!data.ok) {
    const err = new ErrorResponse();

    try {
      const dataParsed = await data.json();
      err.message = dataParsed['message'];
    } catch (e) {
      err.message = data.statusText;
    }

    err.setStatus(data.status);

    throw err;
  }

  return data.json();
};

function _request<T>(path: string, init?: RequestInit): Promise<T> {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': window.csrfTokenValue,
    'X-Requested-With': 'XMLHttpRequest',
  };

  return fetch(path, {
    ...init,
    headers: {
      ...headers,
      ...init?.headers,
    },
  }).then(parseResponse);
}

function _formRequest<T>(path: string, init?: RequestInit): Promise<T> {
  const headers = {
    'Accept': 'application/json',
    'X-CSRF-Token': window.csrfTokenValue,
    'X-Requested-With': 'XMLHttpRequest',
  };

  return fetch(path, {
    ...init,
    headers: {
      ...headers,
      ...init?.headers,
    },
  }).then(parseResponse);
}

function post<T, B>(path: string, body?: B, init?: RequestInit): Promise<T> {
  return _request(path, {
    ...init,
    body: body ? JSON.stringify(body) : undefined,
    method: 'POST',
  });
}

function postForm<T, B>(path: string, body: B, init?: RequestInit): Promise<T> {
  return _formRequest(path, {
    ...init,
    body: body as FormData,
    method: 'POST',
  });
}

function put<T, B>(path: string, body?: B, init?: RequestInit): Promise<T> {
  return _request(path, {
    ...init,
    body: body ? JSON.stringify(body) : undefined,
    method: 'PUT',
  });
}

function remove<T>(path: string, init?: RequestInit): Promise<T> {
  return _request(path, {
    ...init,
    method: 'DELETE',
  });
}

function get<T>(path: string, query?: QueryParams, init?: RequestInit): Promise<T> {
  return _request(`${path}${query ? `?${querySerialize(query)}` : ''}`, {
    ...init,
    method: 'GET',
  });
}

export const request = {
  post,
  postForm,
  put,
  remove,
  get,
};
