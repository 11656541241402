import { required, minLength, maxLength, helpers } from '@vuelidate/validators';

export const name = helpers.withMessage(
  'Only alphabetic characters (A-Z, a-z), spaces, dash (-) and apostrophe (\') are allowed',
  (value: string) => /^[A-z'-\s]*$/.test(value),
);

const hasUppercase = helpers.withMessage(
  'Password must contain at least one uppercase letter (A-Z).',
  (value: string) => /[A-Z]/.test(value),
);

const hasLowercase = helpers.withMessage(
  'Password must contain at least one lowercase letter (a-z).',
  (value: string) => /[a-z]/.test(value),
);

const hasNumber = helpers.withMessage(
  'Password must contain at least one numeric digit (0-9).',
  (value: string) => /\d/.test(value),
);

const noSpaces = helpers.withMessage(
  'Password cannot contain spaces.',
  (value: string) => !/\s/.test(value),
);

export const passwordRules = {
  required,
  minLength: minLength(6),
  maxLength: maxLength(30),
  hasUppercase,
  hasLowercase,
  hasNumber,
  noSpaces,
};
