import { init } from '@sentry/browser';

export const initSentry = () => {
  init({
    dsn: window.sentry.dsn,
    environment: window.sentry.environment,
    release: window.sentry.release,
  });
};

export const triggerTestError = () => {
  if ((new URLSearchParams(location.search)).has('error')) {
    throw new Error('Test error');
  }
};
