import { format } from 'date-fns';

export const wait = (ms: number) => {
  return new Promise((resolve) => {
    const t = setTimeout(() => {
      clearTimeout(t);
      resolve(null);
    }, ms);
  });
};

export const formatUnitAmount = (amount: number = 0, currency: string = 'usd') => {
  const price = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  return price.format(amount);
};

export const formatUnitAmountCents = (amount: number = 0, currency: string = 'usd') => {
  const price = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  return price.format(amount / 100);
};

export function mergeArrayById<T>(a: Array<T & { id: number }>, b: Array<T & { id: number }>) {
  const reduced = a.filter((aItem) => !b.find((bItem) => aItem.id === bItem.id));
  return reduced.concat(b);
}

export const formatToDate = (datetime?: string | null, fmt: string = 'MM/dd/yyyy'): string|null => {
  if (!datetime) {
    return null;
  }
  return format(new Date(datetime), fmt);
};
