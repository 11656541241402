<script setup lang="ts">
import CloseIcon from '@assets/icons/close-round.svg';
import HmrLogo from '@assets/icons/hmr-logo.svg';
import JrnysLogo from '@assets/icons/jrnys-logo.svg';
import { SITE_HANDLE } from '@models/menu';
import { ref } from 'vue';

const isOpen = ref(false);

const toggleMenu = () => {
  isOpen.value = !isOpen.value;
};
const siteHandle = window.siteHandle;
</script>

<template>
  <div class="base-menu relative">
    <Transition name="fade">
      <div
        v-if="isOpen"
        v-click-outside="toggleMenu"
        class="base-menu__container soft-shadow fixed inset-0 z-20 flex w-full flex-col gap-22 bg-white px-10 py-30 lg:absolute lg:inset-auto lg:-right-30 lg:-top-40 lg:w-[370px] lg:gap-28 lg:rounded-[30px] lg:px-30 lg:py-40"
      >
        <div class="flex items-center px-10 lg:px-0">
          <JrnysLogo
            v-if="SITE_HANDLE.JRNYS === siteHandle"
            class="h-[27px] w-[72px] text-white lg:hidden"
          />
          <HmrLogo
            v-if="SITE_HANDLE.HMRPROGRAM === siteHandle"
            class="h-[38px] w-[90px] text-white lg:hidden"
          />
          <button
            class="ml-auto text-turquoise hover:text-turquoise lg:text-warm-grey-30"
            type="button"
            @click="toggleMenu"
          >
            <CloseIcon class="h-[32px] w-[32px]" />
          </button>
        </div>

        <nav class="overflow-y-auto rounded-[30px] bg-white px-30 py-40 lg:overflow-y-visible lg:p-0">
          <slot />
        </nav>
      </div>
    </Transition>

    <button
      class="flex h-[32px] w-[32px] items-center justify-center text-turquoise hover:text-deep-blue"
      type="button"
      @click="toggleMenu"
    >
      <slot name="icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.base-menu__container {
  @include down('lg') {
    background: rgba(206, 200, 192, 0.80);
    backdrop-filter: blur(5px);
  }
}
</style>
