export const useBMI = () => {
  const getBMIFromString = (weight: number, heightString: string): number => {
    const matches = heightString.match(/(\d+)' *(\d+)?/);

    if (matches && matches.length > 0) {
      const feet = parseInt(matches![1]);
      const inches = parseInt(matches![2] || '0');
      const totalInches = (feet * 12) + inches;

      return calculateBMI(weight, totalInches);
    }

    return 0;
  };

  const calculateBMI = (
    weight: number,
    inches: number,
  ) => +(703 * (weight / inches ** 2)).toFixed(2);

  return {
    getBMIFromString,
    calculateBMI,
  };
};
