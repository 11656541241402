<script setup lang="ts">
import { apiUser } from '@api/user';
import { useMemorize } from '@helpers/use-memorize';
import { PAYMENT_STATE } from '@models/cart';
import { useAnalyticsStore, useCartStore } from '@stores/index';
import { onMounted } from 'vue';

const analyticsStore = useAnalyticsStore();
const cartStore = useCartStore();
const memorize = useMemorize('checkout-success-');

/**
 * Takes the checkoutId that sent the payment gateway to redirectUrl and returns the cart.
 * And then it generates the content of the event using it
 */
const fireEvent = async () => {
  const params = new URLSearchParams(location.search);
  const checkoutId = params.get('id');
  const paymentState = params.get('payment-state');

  if (!checkoutId || paymentState !== PAYMENT_STATE.SUCCEEDED) {
    return;
  }

  // If the page is reloaded, the event will not be sent twice
  if (memorize.check(checkoutId)) {
    return;
  }

  const cartResponse = await apiUser.getCartByCheckoutId(checkoutId);
  const cart = cartResponse.data;

  if (!cart) {
    return;
  }

  analyticsStore.purchase(
    cartStore.toAnalyticProducts(cart.items),
    checkoutId,
    cart.total / 100,
    cart.id,
  );

  memorize.commit(checkoutId);
};

onMounted(() => {
  fireEvent();
});
</script>
<template>
  <div />
</template>
