<script setup lang="ts">
import { kebabCase } from 'lodash';

type Props = {
  label?: string
  mask?: string
  maskTokens?: string
  isLock?: boolean
}

const props = defineProps<Props>();
</script>

<template>
  <div
    class="input-group"
    :data-testpl="'input-group-' + kebabCase(label) || 'input-group'"
  >
    <slot />

    <label
      v-if="label"
      :class="{ 'is-lock': isLock }"
    >
      {{ props.label }}
    </label>
  </div>
</template>
